// import data from "../Files/faq_data.json" assert { type: "json" };


const refreshStyles = () => {
    require("./styles.css");
};

/***
 * 
 * FAQ title on the right 
 * div on the left (breadcrumb nav) - position fixed 300 px , top 85px 
 * div one on the right (content) - float left with margin, margin-left 300px 
 * colored background on the sid e
 */

refreshStyles();
export const FAQSample = () => {
    function toggleLink(id) {

        const linkDiv = document.getElementById(id + "-div");

        const linkButton = document.getElementById(id)
        if (!linkDiv) {
            console.error(`Element with id "${id}" not found.`);
            return;
        }

        if (linkDiv.style.display === "none" || !linkDiv.style.display) {

            
            linkDiv.style.display = "block";
            linkButton.textContent = "   ^   "
            return
        } else {
             linkButton.textContent = "   ⌄   "
            linkDiv.style.display = "none";
            return
        }
    }


    function generate_html(){
        const data = require('../Files/faq_data.json');


        console.log()

        var breadcrumb_nav = document.createElement('section')
        breadcrumb_nav.setAttribute('id', "breadcrumb_nav")

        var html_return = document.createElement('section')
        var return_to_top_button = document.createElement('button')
        return_to_top_button.addEventListener('click', return_to_top_listener)
        return_to_top_button.className = "return-to-top-button"
        return_to_top_button.setAttribute("id","return-to-top-button")
        return_to_top_button.textContent = "Back to Top"
        return_to_top_button.onclick = return_to_top_listener

        var hyperlink_header = document.createElement("h2")
        var hyperlink_list = document.createElement('ul')
        var content_list = document.createElement('ul')
        content_list.setAttribute('id', 'content_list')
        hyperlink_list.setAttribute('name', "glance_list")
        
       
        
        // var data_obj = JSON.parse(data)
        


        for(const [title, questions] of Object.entries(data)){
            
            // for each title, make a <a> tag with HREF = #string(title) sep.by dashes
            // create a li 
            
            hyperlink_header.textContent = "Questions at a Glance"
            var hyperlink_title_group_container = document.createElement('li')
            
            var content_title_group_container = document.createElement('li')



            var title_link_string = title.replaceAll(" ", "-")

            var hyperlink_title = document.createElement('a')
            hyperlink_title.setAttribute("href",'#'+title_link_string)   
            hyperlink_title.setAttribute("class", 'question-link-title')
            hyperlink_title.text = title


            var content_title = document.createElement("h2")
            content_title.setAttribute("id", title_link_string)
            content_title.textContent = title

            var toggle_button = document.createElement('button')
            toggle_button.addEventListener("click", button_listener)

            toggle_button.name = "toggle_button"

            toggle_button.id = title_link_string + '-link'
            toggle_button.className = "toggle-button"

            toggle_button.textContent="   ⌄   "


            var hyperlink_question_container = document.createElement('div')
            hyperlink_question_container.setAttribute("class", "toggle-link")
            hyperlink_question_container.setAttribute('id', title_link_string + '-link-div')
            
            var content_question_container = document.createElement('div')


            
            var hyperlinks_question_list = document.createElement('ul')
            var content_question_list = document.createElement('ul')

           
            for (const [question_text, question_answer_paragraphs] of Object.entries(questions)){
                var hyperlink_indv_question_container = document.createElement('li')
                var content_indv_question_container = document.createElement('li')

                var question_link_string = question_text.replaceAll(" ", "-")

                var hyperlink_question = document.createElement('a')
                hyperlink_question.setAttribute("href", '#'+question_link_string)
                // add sizes here 
                hyperlink_question.text = question_text


                var content_question = document.createElement('h3')
                content_question.setAttribute("id", question_link_string)
                content_question.textContent = question_text

                var content_question_paragraphs_container = document.createElement("div")


                for (var paragraph of question_answer_paragraphs){
                    var paragraph_element = document.createElement("p")
                    paragraph_element.textContent = paragraph
                    content_question_paragraphs_container.appendChild(paragraph_element)

                    
                }



                hyperlink_indv_question_container.appendChild(hyperlink_question)
                content_indv_question_container.appendChild(content_question)
                content_indv_question_container.appendChild(content_question_paragraphs_container)
                

                hyperlinks_question_list.appendChild(hyperlink_indv_question_container)
                content_question_list.appendChild(content_indv_question_container)


            }
            hyperlink_question_container.appendChild(hyperlinks_question_list)
            content_question_container.appendChild(content_question_list)


            hyperlink_title_group_container.appendChild(hyperlink_title)
            hyperlink_title_group_container.appendChild(toggle_button)
            hyperlink_title_group_container.appendChild(hyperlink_question_container)
           

            content_title_group_container.appendChild(content_title)
            content_title_group_container.appendChild(content_question_container)



            
            
            hyperlink_list.appendChild(hyperlink_title_group_container)
            content_list.appendChild(content_title_group_container)
            

        }
        // html_return.appendChild(hyperlink_header)
        html_return.appendChild(return_to_top_button)
        // html_return.appendChild(hyperlink_list)
        html_return.appendChild(content_list)
        html_return.setAttribute("id", "faq-section");

        return_to_top_button.appendChild(hyperlink_list)
        return html_return

        
    }

    var html = generate_html()

    if (!document.querySelector("#faq-section")) {
        document.body.appendChild(html);
    }

    function button_listener(event){
        const target = event.target.closest("button"); // Or any other selector.
        
        if(target){
          
          event.stopPropagation();
          toggleLink(target.id)
        }
    }

    let return_to_top_button = document.getElementById("return-to-top-button");

    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        return_to_top_button.style.display = "block";
    } else {
        return_to_top_button.style.display = "none";
    }
    }


    function return_to_top_listener(event) {
        if(event){
            document.body.scrollTop = 0; 
            document.documentElement.scrollTop = 0; 

        }

      }

 
    return null


};