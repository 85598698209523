
import { Header } from "./Header";
export function Home(){
    return(
        <div className="bg-gray-900">

        <Header/>
        <div className=" text-left selection:bg-gray-700 grid gap-4 bg-fixed min-h-screen min-height-screen bg-gray-900 max-w-screen-xl">
        
           <div className = " m-8"> 
           <h1 className=" text-left my-4 text-2xl text-red-200 font-TrapBold"> Hi! I'm Ella :-) </h1>
           <h1 className=" text-left my-4 text-2xl text-purple-200 font-TrapBold"> I'm a senior (already?) at <a target="_blank" className="text-purple-400 hover:underline" href="https://www.northwestern.edu">Northwestern University</a> studying Computer Science with a concentration in Human Computer Interaction.</h1>

           <h1 className=" text-left my-4 text-2xl text-blue-200 font-TrapBold"> I'm passionate about education and supporting students so that they can learn effectively. More specifically, I love working on systems that support student's <i> metacognition </i> - whether they are systems that teach metacognitive strategies or ones that help students reflect on their existing strategies. </h1>

           <h1 className=" text-left my-4 text-2xl text-green-200 font-TrapBold"> I'm currently working at <a target="_blank" className="text-green-400 hover:underline" href="https://www.mathacademy.com/">Math Academy</a> as a researcher and developer. Before this, I interned at the <a target="_blank" className="text-green-400 hover:underline" href="https://learning-engineering-virtual-institute.org/">Learning Engineering Virtual Institute</a> affiliated with the  <a target="_blank" className="text-green-400 hover:underline" href="https://digitalharbor.org/">Digital Harbor Foundation</a>. </h1>



           <h1 className=" text-left my-4 text-2xl text-orange-200 font-TrapBold"> At school, I participated in <a target="_blank" className="text-orange-400 hover:underline" href="https://dtr.northwestern.edu">Design, Technology, and Research</a>. In that program,
           I worked on  a project called <a target="_blank" className="text-orange-400 hover:underline" href="https://dtr.northwestern.edu/projects/recxU2iSY4G2bHdCj"> Process Adjustment by Tackling Hangups </a>,  building a tool to help students <i> reflect</i> on their metacognitive practices. </h1> 
        </div>
        </div>
        </div >

    );
}

